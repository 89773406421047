import { serviceFeeType } from "./serviceFeeType";

export const serviceFeeReducer = (state = 0, action) => {
  switch (action.type) {
    case serviceFeeType.SET_SERVICE_FEE:
      return action.payload;
    default:
      return state;
  }
};
