import { useNavigate } from "react-router-dom";
import { MapPinIcon } from "../../assets/icons";
import { appstoreImage, gplayImage, MSLLogo } from "../../assets/images"
import { Typography } from "../atoms/Typography";
import { socialMediaList } from "../../constanta";


const Footer = () => {
  const year = new Date().getFullYear();
  const navigate = useNavigate();
  const data = {
    address: 'Jalan Raya Solo Baki Km 5 Nomor 81 C, Kecamatan Grogol Kabupaten Sukoharjo - Solo Jawa Tengah 57552',
    officeHour: 'Senin - Jumat : 09.00 - 17.00 WIB'
  };
  const footerList = [
    {
      title: 'LAYANAN',
      child: [
        { label: 'Jadwal Lelang', linkedTo: '/auction-schedule' },
        { label: 'Beli NIPL', linkedTo: '/buy-nipl' },
        { label: 'Titip Lelang', linkedTo: '/consignment-auction' },
        { label: 'Garansi', linkedTo: '/' },
      ]
    },
    {
      title: 'INFORMASI',
      child: [
        // { label: 'Tentang Kami', linkedTo: '/about-us' },
        // { label: 'Prosedur', linkedTo: '/procedure' },
        // { label: 'Info MSL', linkedTo: '/blogs' },
        { label: 'Kebijakan Privasi', linkedTo: '/privacy-policy' },
        { label: 'Syarat & Ketentuan', linkedTo: '/terms-and-conditions' },
      ]
    },
    {
      title: 'BANTUAN',
      child: [
        { label: 'Hubungi Kami', linkedTo: '/contact-us' },
        { label: 'FAQ', linkedTo: '/faq' },
        { label: 'Lokasi Kami', linkedTo: '/our-locations' },
      ]
    },
  ];

  const handleClick = (link) => {
    navigate(link)
  };

  return (
    <footer className="w-full p-4 lg:py-8 lg:px-[50px] bg-white border-t border-color-neutral-300">
      <div className="flex flex-col lg:flex-row items-start lg:justify-between lg:items-center space-y-2 pb-6">
        <img alt="MSL Kopkara Auction" className="content z-[2] w-[118.55px]" src={MSLLogo} />
        <div className="flex space-x-3 justify-between lg:justify-end w-full items-center">
          <Typography
            color="secondary"
            size="md"
            text={`Follow Us`}
            weight="normal"
          />
          <div className="flex space-x-4">
            {
              socialMediaList.map(({ icon: Icon, name, url }) => (
                // <div key={name} className="cursor-pointer">
                //   <Icon className="text-color-neutral-700" />
                // </div>
                <a href={url} target="_blank" rel="noreferrer" key={name}>
                  <Icon className="text-color-neutral-700" />
                </a>
              ))
            }
          </div>
        </div>
      </div>

      <div className="py-6 border-t gap-4 border-color-neutral-300 grid grid-cols-2 lg:grid-cols-4">
        {
          footerList.map((footer, index) => (
            <div
              key={`footer list ${index + 1}`}
              className="space-y-[10px]"
            >
              <Typography
                color="secondary"
                size="base"
                text={footer.title}
                weight="semibold"
              />
              {
                footer.child.map((el, idx) => (
                  <div
                    key={`footer list child ${index + 1} - ${idx + 1}`}
                    className="cursor-pointer"
                    onClick={() => handleClick(el.linkedTo)}
                  >
                    <Typography
                      color="secondary"
                      size="sm"
                      text={el.label}
                      weight="regular"
                    />
                  </div>
                ))
              }
            </div>
          ))
        }
        <div className="flex flex-col space-y-1">
          <Typography
            color="secondary"
            size="sm"
            text="Dapatkan Aplikasi"
            weight="regular"
          />
          <div className="flex space-x-1">
            <button>
              <img src={gplayImage} alt="msl-get-from-google-play" className="max-h-12" />
            </button>
            <button>
              <img src={appstoreImage} alt="msl-get-from-google-play" className="max-h-12" />
            </button>
          </div>
        </div>
      </div>

      <div className="py-6 border-t border-color-neutral-300 flex flex-col space-y-3 lg:flex-row justify-between items-start lg:items-center">
        <div className="flex items-start lg:items-center space-x-2">
          <MapPinIcon className="text-color-neutral-500 h-5 w-5" />
          <Typography
            color="secondary"
            size="sm"
            text={data.address}
            weight="normal"
          />
        </div>
        <Typography
          color="secondary"
          size="sm"
          text={data.officeHour}
          weight="normal"
          className="ml-5"
        />
      </div>

      <div className="pt-[10px] border-t border-color-neutral-300 text-center">
        <Typography
          color="secondary"
          size="sm"
          text={`MSL  © ${year} all right reserved`}
          weight="normal"
        />
      </div>
    </footer>
  )
}

export default Footer
