import { axiosGlobal, axiosPublic } from "./config";

const postLogin = async (body) => {
  const { data: response } = await axiosPublic.post(
    `/auth/in`, body
  )
  return response.data
}

const postLogout = async () => {
  const { data: response } = await axiosGlobal.post(
    `/auth/out`
  )
  return response.data
}

const postRegister = async (body) => {
  const { data: response } = await axiosGlobal.post(
    `/auth/customer/registration`, body
  )
  return response.data
}

const postResentVerification = async (body) => {
  const { data: response } = await axiosGlobal.post(
    `/auth/customer/resend-verification`, body
  )
  return response.data
}

const postVerification = async (token) => {
  const { data: response } = await axiosPublic.post(
    `/auth/customer/verification/${token}`, token
  )
  return response.data
}

const postForgotPassword = async (body) => {
  const { data: response } = await axiosGlobal.post(
    `/auth/customer/forgot-password`, body
  )
  return response.data
}

const postResetPassword = async (body, token) => {
  const { data: response } = await axiosPublic.post(
    `/auth/customer/update-password/${token}`, body
  )
  return response.data
}

export {
  postLogin,
  postLogout,
  postRegister,
  postResentVerification,
  postForgotPassword,
  postResetPassword,
  postVerification,
}