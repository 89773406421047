import Countdown from "react-countdown"
import { Typography } from "../../atoms/Typography"
import { useRef, useState } from "react"

const ContentSuccessRegister = ({ handleResend }) => {
  const ref = useRef(Date.now())
  const [countdown, setCountdown] = useState({
    key: 1,
    show: true
  })
  const time = 300000
  const renderer = ({ minutes, seconds, completed }) => {
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    if (completed) {
      // Render a complete state
      return <button
        className='text-Text-primary text-sm font-semibold'
        onClick={handleClickResend}
      >
        Kirim ulang
      </button>
    } else {
      // Render a countdown
      return (
        <span>
          {`0${minutes}`}:{formattedSeconds}
        </span>
      );
    }
  };
  const handleClickResend = () => {
    ref.current = Date.now()
    setCountdown(prev => ({
      key: prev.key + 1,
      show: true
    }))
    handleResend()
  }
  return (
    <div className='justify-center items-center flex space-x-2'>
      <Typography
        color="secondary"
        size="sm"
        text='Tidak ada email masuk?'
      />
      <span className="text-Text-primary text-sm font-semibold">
        <Countdown
          key={countdown.key}
          date={ref.current + time}
          zeroPadTime={3}
          renderer={renderer}
        />
      </span>
    </div>
  )
}

export default ContentSuccessRegister