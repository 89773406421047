import { FacebookIcon, InstagramIcon, LinkedinIcon, YoutubeIcon } from "../assets/icons";
const startYear = 1945
const currentYear = new Date().getFullYear();
const years = Array(currentYear - startYear + 1).fill(null).map((_, index) => ({
  value: currentYear - index,
  label: currentYear - index
}))
const months = [
  {
    value: "1",
    label: "Januari",
  },
  {
    value: "2",
    label: "Februari",
  },
  {
    value: "3",
    label: "Maret",
  },
  {
    value: "4",
    label: "April",
  },
  {
    value: "5",
    label: "Mei",
  },
  {
    value: "6",
    label: "Juni",
  },
  {
    value: "7",
    label: "Juli",
  },
  {
    value: "8",
    label: "Agustus",
  },
  {
    value: "9",
    label: "September",
  },
  {
    value: "10",
    label: "Oktober",
  },
  {
    value: "11",
    label: "November",
  },
  {
    value: "12",
    label: "Desember",
  },
];
const days = Array(31).fill(null).map((_, index) => ({
  value: index + 1,
  label: index + 1
}))

const socialMediaList = [
  { name: 'fb', icon: FacebookIcon, url: 'https://www.google.co.id' },
  { name: 'yt', icon: YoutubeIcon, url: '' },
  { name: 'ig', icon: InstagramIcon, url: '' },
  { name: 'li', icon: LinkedinIcon, url: '' },
]

const regexForNumber = /.*[0-9].*/;
const regexForUppercase = /.*[A-Z].*/;
const regexForSpecialCharacter = /.*[^A-Za-z0-9].*/;

const handleBadgeType = {
  'PENDING': 'warning',
  'PAID': 'success',
  'EXPIRE': 'danger'
}
const handleBadgeLabel = {
  'PENDING': 'Menunggu Pembayaran',
  'PAID': 'Lunas',
  'EXPIRE': 'Wanprestasi'
}

const bankList = [
  // {
  //   label: 'Bersama Virtual Account',
  //   value: '04_VA_BERSAMA'
  // },
  // {
  //   label: 'BCA Virtual Account',
  //   value: '05_VA_BCA'
  // },
  {
    label: 'Mandiri Virtual Account',
    value: '06_VA_MANDIRI'
  },
  {
    label: 'BNI Virtual Account',
    value: '07_VA_BNI'
  },
  // {
  //   label: 'BRI Virtual Account',
  //   value: '08_VA_BRI'
  // },
  {
    label: 'Danamon Virtual Account',
    value: '17_VA_DANAMON'
  },
  {
    label: 'CIMB Niaga Virtual Account',
    value: '18_VA_NIAGA'
  },
  {
    label: 'Permata Virtual Account',
    value: '19_VA_PERMATA'
  },
  {
    label: 'Transfer Bank',
    value: '99_MANUAL'
  }
]

export {
  days,
  months,
  years,
  bankList,
  socialMediaList,
  regexForNumber,
  regexForUppercase,
  regexForSpecialCharacter,
  handleBadgeType,
  handleBadgeLabel
}